import './control.scss'

import CustomHTMLElement from 'html-element'
import { sendRequest } from 'js-api'

const FETCH_INTERVAL = 5000

class FixtureControl extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.apiBase = this.getAttribute('data-api')
    this.apiToken = this.getAttribute('data-api-token')
    this.fixtureId = this.getAttribute('data-fixture-id')

    this.vidiEventsBody = this.querySelector('table[control-table=vidi] tbody')
  }

  _runConnected() {
    super._runConnected()

    this.fetchData()
  }

  disconnectedCallback() {
    this.cancelFetchTimer()
  }

  //////////

  startFetchTimer() {
    if (this.fetchTimeout) { return }
    if (this.fixtureInactive) { return }

    window.setTimeout(this.fetchData.bind(this), FETCH_INTERVAL)
  }
  cancelFetchTimer() {
    if (!this.fetchTimeout) { return }

    window.clearTimeout(this.fetchTimeout)
    this.fetchTimeout = null
  }

  async fetchData() {
    this.fetchTimeout = null

    if (!this.fixturePlayers) {
      await this.fetchPlayers()
    }

    await Promise.all([
      this.fetchChat(),
      this.fetchEvents(),
      this.fetchStandings()
    ])
    this.startFetchTimer()
  }

  // Fetches

  async apiGet(url) {
    return sendRequest('GET', `${this.apiBase}/${url}`, { headers: { token: this.apiToken } })
  }

  async fetchChat() {
    return []
  }

  async fetchEvents() {
    let response = await this.apiGet(`fixtures/${this.fixtureId}/events`)
    if (!response.success) {
      this.fixtureInactive = true
      this.vidiEventsBody.innerHTML = '<tr class="control-table__message"><td colspan="4">An error occurred</td></tr>'
      return
    }

    this.vidiEventsBody.innerHTML = ''
    response.content.data.forEach(event => {
      if (event.pointsId) {
        if (event.pointsDeleted) {
          event.points = 'DEL'
        }

        let element = document.createElement('tr')
        if (event.points < 0) {
          element.classList.add('control-table__negative')
        }

        let player = this.fixturePlayers[event.playerId]

        element.innerHTML = `<td>${event.clock}</td>
                             <td>${player.matchName}</td>
                             <td>${event.reason}</td>
                             <td>${event.points}</td>`
        this.vidiEventsBody.appendChild(element)
      }
    })

    if (this.vidiEventsBody.lastElementChild) {
      this.vidiEventsBody.lastElementChild.scrollIn()
    }
  }

  async fetchPlayers() {
    let response = await this.apiGet(`fixtures/${this.fixtureId}/players/all`)
    this.fixturePlayers = response.content.data.reduce((o, i) => { o[i.id] = i; return o }, {})
  }

  async fetchStandings() {
    return []
  }
}
window.customElements.define('fixture-control', FixtureControl)
