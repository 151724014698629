import './kit_selector.scss'

import CustomHTMLElement from 'html-element'
import Popover from 'popover'

////////// Team

class TeamKitSelector extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.objectPart = this.getAttribute('data-object-part')

    this.setupEvents()
  }

  setupEvents() {
    let blankKit = this.querySelector('.kit-selector__kit--blank')
    blankKit.addEventListener('click', this.addKit.bind(this))
  }

  addKit() {
    let popover = new Popover({ removeOnHide: true })
    popover.addEventListener('popover:display', () => {
      popover.querySelectorAll('[data-inject-data]').forEach(button => {
        let buttonData = button.getJsonAttribute('data-inject-data')
        button.href = `${window.location.pathname}/add-kit?object_part=${this.objectPart}&kit_id=${buttonData.id}`
        button.setAttribute('data-method', 'post')
      })
    })

    popover.loadContent('/football/kits/inject')
    popover.show()
  }
}
window.customElements.define('team-kit-selector', TeamKitSelector)

////////// Fixture

class FixtureKitSelector extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.teamId = this.getAttribute('data-team-id')
    this.objectPart = this.getAttribute('data-object-part')

    if (this.objectPart.endsWith('_out')) {
      this.kitType = 'out'
    } else {
      this.kitType = 'gk'
    }

    this.setupEvents()
  }

  setupEvents() {
    let kit = this.querySelector('.kit-selector__kit')
    kit.addEventListener('click', this.chooseKit.bind(this))
  }

  chooseKit() {
    let popover = new Popover({ removeOnHide: true })
    popover.addEventListener('popover:display', () => {
      popover.querySelectorAll('[data-inject-data]').forEach(button => {
        let buttonData = button.getJsonAttribute('data-inject-data')
        button.href = `${window.location.pathname}/choose-kit/${this.objectPart}?kit_id=${buttonData.id}`
        button.setAttribute('data-method', 'post')
      })
    })

    popover.loadContent(`/football/kits/inject?team_id=${this.teamId}&kit_type=${this.kitType}`)
    popover.show()
  }

}
window.customElements.define('fixture-kit-selector', FixtureKitSelector)
