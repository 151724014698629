import './kits.scss'

import CustomHTMLElement from 'html-element'

////////// Input

class KitInput extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.fileInput = this.querySelector('file-input')
    this.hiddenInput = this.querySelector('input[name=built_kit]')
    this.textElement = this.querySelector('span.kit-display')

    this.updateDisplay()
  }

  useSvg(svg) {
    this.hiddenInput.value = svg
    this.updateDisplay()
  }

  updateDisplay() {
    if (this.hiddenInput.value && this.hiddenInput.value.length > 0) {
      this.fileInput.style.display = 'none'
      this.textElement.innerText = 'Using Built Kit'
    } else {
      this.fileInput.style.display = ''
      this.textElement.innerText = ''
    }
  }

}
window.customElements.define('kit-input', KitInput)

////////// Builder

class KitBuilder extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.svg = this.querySelector('svg')

    let paths = Array.from(this.svg.children)
    let index = 0
    paths.forEach(path => {
      path.initialOrder = index
      path.order = index

      index += 1
    })

    this.svgWrapper = document.createElement('div')
    this.svgWrapper.classList.add('kit-builder-svg')
    this.svgWrapper.appendChild(this.svg)

    this.form = this.querySelector('form')
    this.colorInputs = this.querySelectorAll('color-custom')

    this.popover = this.closest('pop-over')

    this.setupEvents()
  }

  _runConnected() {
    super._runConnected()

    document.body.appendChild(this.svgWrapper)
  }

  disconnectedCallback() {
    this.svgWrapper.remove()
  }

  setupEvents() {
    this.form.addEventListener('submit', this.buildKit.bind(this))

    this.colorInputs.forEach((input) => {
      input.addEventListener('color-change', this.inputChanged.bind(this))
      input.parentElement.querySelector('input[inputmode=decimal]').addEventListener('change', this.orderChanged.bind(this))
    })
  }

  //////////

  inputChanged(event) {
    let input = event.target
    let id = input.textInput.id.replace(/^.*?_/, '')
    let path = this.svg.querySelector(`#${id}`)

    if (input.value && input.value.length > 0) {
      path.setAttributeNS(null, 'fill', input.value)
    } else {
      path.removeAttributeNS(null, 'fill')
    }
  }

  orderChanged(event) {
    let input = event.target
    let id = input.id.replace(/^.*?_/, '').replace('_order', '')
    let path = this.svg.querySelector(`#${id}`)

    if (input.value && input.value.length > 0) {
      path.order = parseInt(input.value) * 100 + path.initialOrder
    } else {
      path.order = path.initialOrder
    }

    let paths = Array.from(this.svg.children)
    paths.sort((a, b) => a.order - b.order)
    paths.forEach(path => this.svg.appendChild(path))
  }

  buildKit(event) {
    event.preventDefault()

    let kitInput = document.querySelector('kit-input')
    Array.from(this.svg.children).forEach(path => {
      if (!path.hasAttribute('fill')) {
        path.remove()
      }
    })

    let content = `<?xml version="1.0" encoding="utf-8"?>${this.svg.outerHTML}`
    kitInput.useSvg(content)

    this.popover.hide()

    return false
  }

}
window.customElements.define('kit-builder', KitBuilder)
